<template>
    <v-row justify="start">
      <v-card v-for="(groupedEvent,i) in groupedEvents" :key="groupedEvent.id" class="mb-3 mr-3"  max-width="344">
        <v-card-title>{{ $t(`weekType.${i}`) }}</v-card-title>
        <v-card-subtitle>{{ $t('common.words.week') }} :</v-card-subtitle>
        <v-card-text>
          <v-chip
            color="primary"
            class="mr-3"
            v-for="event in groupedEvent" :key="event.id"
          >{{ event.weekNumber}}</v-chip>
        </v-card-text>
      </v-card>
    </v-row>
</template>

<script>
export default {
  name: 'GroupedCard',
  props: {
    groupMode: { type: String, require: true },
    data: { type: Array, require: true },
  },
  computed: {
    groupedEvents() {
      return this._(this.data)
        .sortBy('weekNumber')
        .groupBy('weekType')
        .value();
    },
  },
};
</script>

<style scoped>

</style>
