<template>
  <div>
    <v-simple-table>
      <thead>
      <tr>
        <th class="text-center">{{ $t('common.labels.week_type') }}</th>
        <th class="text-center">{{ $t('common.labels.week_number') }}</th>
        <th class="text-center">{{ $t('common.labels.week_number') }} {{ $t('common.words.mmax') }}</th>
        <th class="text-center">{{ $t('common.words.forms') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(event, i) in weekEvents" :key="event.id">
        <td class="text-center">{{ $t(`weekType.${event.weekType}`) }}</td>
        <td class="text-center">{{ event.weekNumber }}</td>
        <td class="text-center">{{ event.weekNumberMax !== event.weekNumber ? event.weekNumberMax : '-' }}</td>
        <td class="text-center">
          <v-row justify="start" class="flex-wrap" no-gutters>
            <div v-for="(formId, key) in event.formIds" :key="key">
              <v-chip
                class="px-4 px-2 mx-2 my-1" color="primary" link pill
                @click.prevent="goToForm(formId)"
              >
                {{formId}}
              </v-chip>
            </div>
          </v-row>
        </td>
        <td class="text-center">
          <v-btn icon color="primary"
                 @click.stop="editWeekEvent(i)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <confirm-button width="400px" @click="remove(event.id)">
            <template v-slot:button="{ on, attrs }">
              <v-btn
                icon color="error"
                v-on="on" v-bind="attrs"
                :disabled="deleteMixin.state === deleteMixin.STATES.LOADING"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:title>
              {{ $t('pages.week_events.list.delete_validation_1') }}<br/>
              {{ $t('pages.week_events.list.delete_validation_2') }}
            </template>

            <template v-slot:message>
              {{ $t('pages.week_events.list.delete_validation_message') }}
            </template>
          </confirm-button>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <paginator
      v-if="pagination"
      :pagination="pagination"
      @update="$emit('update-pagination', $event)"
    />
  </div>
</template>

<script>
import Paginator from '@/components/util/paginator.vue';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import DeleteMixin from '@/mixins/delete.mixin';
import WeekEventService from '@/services/api/weekEvent.service';
import FormService from '@/services/api/form.service';

export default {
  name: 'WeekEventList',
  mixins: [DeleteMixin],
  props: {
    weekEvents: { type: Array, required: true },
    pagination: { type: Object, required: true },
  },
  components: {
    Paginator,
    ConfirmButton,
  },
  methods: {
    async goToForm(formId) {
      const form = await FormService.fetchForm(formId);
      await this.$router.push({ name: 'form-info', params: { formId: form.data.id, form: form.data } });
    },
    removeFromList(id) {
      this.$emit('remove', id);
    },
    deleteWeekEvent(id) {
      return WeekEventService.deleteWeekEvent(id);
    },
    editWeekEvent(index) {
      const weekEvent = { ...this.weekEvents[index] };
      this.$router.push({ name: 'week-event-info', params: { weekEventId: weekEvent.id, weekEvent } });
    },
  },
  created() {
    // this.events = [...this.weekEvents];

    this.deleteMixin.config = {
      delete: 'deleteWeekEvent',
      callback: 'removeFromList',
    };
  },
};
</script>

<style scoped>
  a {
    text-decoration: none;
    color: white;
  }
</style>
