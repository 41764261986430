<template>
  <v-card outlined flat>
    <v-card-title>
      {{ $t('common.action.create') }} {{ $t('common.messages.a_new_masc_fem') }} {{ $t('common.words.mevent') }}
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <div style="display: flex; flex-direction: row">
          <v-text-field
            v-bind:label="$t('common.labels.week_number')"
            name="weekNumber"
            type="number"
            v-model="event.weekNumber"
            @keypress.enter.prevent="submit"
            :rules="[
            (v) => isValid(v) || $t('errors.invalid_number'),
          ]"
          />
          <div style="width: 5%"></div>
          <v-text-field
            v-bind:label="$t('common.labels.week_number') + ' ' + $t('common.words.mmax') + ' (' + $t('common.words.moptional') + ')'"
            name="weekNumberMax"
            type="number"
            v-model="event.weekNumberMax"
            @keypress.enter.prevent="submit"
            :rules="[
            (v) => isValidMax(v, event.weekNumber) || $t('errors.invalid_number'),
          ]"
          />
        </div>
        <v-select
          v-bind:label="$t('common.labels.week_type')"
          :items="weekTypes"
          v-model="event.weekType"
        >
          <template v-slot:item="{ item }">
            {{ $t(`weekType.${item}`) }}
          </template>
          <template v-slot:selection="{ item }">
            {{ $t(`weekType.${item}`) }}
          </template>
        </v-select>
        <form-select v-if="false"
          v-model="event.formId"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn depressed color="primary" @click="submit"
             :loading="createMixin.state === createMixin.STATES.CREATING"
             :disabled="!validForm">
        {{ $t('common.action.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import WeekEventService from '@/services/api/weekEvent.service';
import FormSelect from '@/components/forms/FormSelect.vue';
import CreateMixin from '@/mixins/create.mixin';
import { toNumber } from 'lodash';

export default {
  name: 'week-event-form',
  mixins: [CreateMixin],
  components: { FormSelect },
  data: () => ({
    event: {
      weekNumber: null,
      weekNumberMax: null,
      weekType: null,
      formIds: [],
    },
    validForm: false,
    weekTypes: [
      'INSCRIPTION', 'INSCRIPTION_POSTPARTUM', 'AMENORRHEA', 'POSTPARTUM',
    ],
  }),
  methods: {
    isValidMax(weekNumberMax, weekNumber) {
      return this.isValid(weekNumber)
        ? (this.isValid(weekNumberMax) && toNumber(weekNumberMax) >= toNumber(weekNumber)) || weekNumberMax === ''
        : true;
    },
    isValid(v) {
      return /^[+-]?\d+$/.test(v);
    },
    createWeekEvent() {
      return WeekEventService.createWeekEvent(this.event);
    },
    submit() {
      this.$refs.form.validate();
      if (!this.validForm) return;
      this.create();
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createWeekEvent',
    };
  },
};
</script>
