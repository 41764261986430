<template>
  <div>
    <v-col class="text-right">
      <v-btn @click="toggleMode" color="primary">
        <span v-if="groupedByForm">
          {{ $t('pages.week_events.group_by_week_type') }}
        </span>
        <span v-else>{{ $t('pages.week_events.group_by_form') }}</span>
      </v-btn>
    </v-col>

    <div v-if="groupedByForm">
      <div v-for="(event,i) in events" :key="i" >
        <h3 class="mt-4">{{ $t('common.words.form') }} {{ i }}</h3>
        <v-divider class="my-4"></v-divider>
        <week-type-grouped-card :data="event" />
      </div>
    </div>

    <div v-else>
      <div v-for="(event,i) in events" :key="i" >
        <h3 class="mt-4">{{ $t(`weekType.${i}`) }}</h3>
        <v-divider class="my-4"></v-divider>
        <form-grouped-card :data="event" />
      </div>
    </div>
    <paginator
      v-if="pagination"
      :pagination="pagination"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>

import WeekTypeGroupedCard from '@/components/weekevent/cards/WeekTypeGroupedCard.vue';
import FormGroupedCard from '@/components/weekevent/cards/FormGroupedCard.vue';
import paginator from '@/components/util/paginator.vue';

const GROUPEMODE = {
  BYFORM: 'formId',
  BYWEEK: 'weekType',
};
export default {
  name: 'WeekEventCards',
  components: { WeekTypeGroupedCard, FormGroupedCard, paginator },
  props: {
    weekEvents: { type: Array, required: true },
    pagination: { type: Object, required: true },
  },
  data: () => ({
    GROUEPMODE: GROUPEMODE,
    currentMode: GROUPEMODE.BYFORM,
  }),
  methods: {
    toggleMode() {
      if (this.groupedByForm) {
        this.currentMode = GROUPEMODE.BYWEEK;
      } else {
        this.currentMode = GROUPEMODE.BYFORM;
      }
    },
  },
  computed: {
    groupedByForm() {
      return this.currentMode === GROUPEMODE.BYFORM;
    },
    events() {
      return this._(this.weekEvents)
        .groupBy(this.currentMode)
        .value();
    },
  },
};
</script>

<style scoped>

</style>
