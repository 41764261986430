<template>
  <v-select
    :label="label || $t('common.words.form')"
    :loading="fetchMixin.state === fetchMixin.STATES.LOADING"
    :items="forms"
    item-value="id"
    item-text="label"
    :value="value"
    @change="$emit('input', $event)"
    :rules="[(v) => v !== null || $t('errors.choose_form'), ...rules]"
  >
<!--    <template v-slot:prepend-item>-->
<!--      <div class="selectTop"></div>-->
<!--    </template>-->

    <template v-slot:item="{ item: { id, label } }">
      #{{ id }} - {{ label }}
    </template>

    <template v-slot:append-item>
      <v-list-item>
        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import Paginator from '@/components/util/paginator.vue';
import FormService from '@/services/api/form.service';
import FetchMixin from '@/mixins/fech.mixin';

export default {
  name: 'form-select',
  mixins: [FetchMixin],
  components: { Paginator },
  props: {
    label: { default: null },
    value: { default: null },
    rules: { default: () => [] },
  },
  data: () => ({
    forms: [],
  }),
  methods: {
    fetchForms() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return FormService.fetchForms({ page, size });
    },
  },
  created() {
    this.fetchMixin.config = {
      fetch: 'fetchForms',
      dataField: 'forms',
    };
    this.fetch();
  },
};
</script>
