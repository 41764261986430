<template>
  <v-col>
    <div id="info-button">
      <v-btn icon color="info">
        <v-icon>mdi-information</v-icon>
      </v-btn>
      {{ $t('common.words.help') }}
      <div id="tooltip-button">
        <div style="display: flex; flex-direction: column">
          <div>
            {{ $t('pages.week_events.help.message') }}
          </div>
          <div>
            <v-btn icon color="primary">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <span>{{ $t('pages.week_events.help.modification') }}</span>
          </div>
          <div>
            <v-btn icon color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <span>{{ $t('pages.week_events.help.deletion') }}</span>
          </div>
        </div>
      </div>
    </div>

    <week-event-form @created="addEvent" />

    <v-card class="mt-4" flat tile :loading="fetchMixin.state === fetchMixin.STATES.LOADING">
      <v-card-text v-if="fetchMixin.state === fetchMixin.STATES.ERROR">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state === fetchMixin.STATES.EMPTY">
        {{ $t('errors.unavailable', {name: $t('common.words.mevents')}) }}
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state === fetchMixin.STATES.IDLE || fetchMixin.state === fetchMixin.STATES.LOADING">
<!--        <v-toolbar-->
<!--          dense-->
<!--          flat-->
<!--          prominent-->
<!--        >-->
<!--          <v-toolbar-items>-->
<!--            <v-btn icon @click="toggleMode">-->
<!--              <v-icon :color="listBtnColor">mdi-view-sequential</v-icon>-->
<!--            </v-btn>-->
<!--            <v-btn icon @click="toggleMode">-->
<!--              <v-icon :color="groupeBtnColor">mdi-view-grid</v-icon>-->
<!--            </v-btn>-->
<!--          </v-toolbar-items>-->
<!--        </v-toolbar>-->
        <div v-if="fetchMixin.pagination">
          <week-event-list
            @remove="remove"
            :pagination="fetchMixin.pagination"
            :week-events="events"
            v-if="isInListMode"
            @update-pagination="updatePagination"
          />
          <week-event-cards
            :pagination="fetchMixin.pagination"
            :week-events="events"
            v-else
          />
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import WeekEventService from '@/services/api/weekEvent.service';
import WeekEventForm from '@/components/weekevent/WeekEventForm.vue';
import FetchMixin from '@/mixins/fech.mixin';
import WeekEventList from '@/components/weekevent/WeekEventList.vue';
import WeekEventCards from '@/components/weekevent/WeekEventCards.vue';

const VIEWMODE = {
  LIST: 'list', CARD: 'card',
};
export default {
  name: 'WeekEventsView',
  mixins: [FetchMixin],
  components: {
    WeekEventCards,
    WeekEventList,
    WeekEventForm,
  },
  data: () => ({
    events: [],
    VIEWMODE,
    currentViewMode: VIEWMODE.LIST,
  }),
  methods: {
    addEvent(event) {
      this.events = [...this.events, event];
    },
    remove(id) {
      this.events = this.events.filter((event) => event.id !== id);
    },
    fetchWeekEvents() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return WeekEventService.fetchWeekEvents({ page, size });
    },
    toggleMode() {
      if (this.isInListMode) {
        this.currentViewMode = VIEWMODE.CARD;
      } else {
        this.currentViewMode = VIEWMODE.LIST;
      }
    },
  },
  computed: {
    isInListMode() {
      return this.currentViewMode === VIEWMODE.LIST;
    },
    listBtnColor() {
      return (this.isInListMode) ? 'primary' : '';
    },
    groupeBtnColor() {
      return (this.isInListMode) ? '' : 'primary';
    },
  },
  created() {
    this.fetchMixin.config = {
      fetch: 'fetchWeekEvents',
      dataField: 'events',
    };
    this.fetch();
  },
};
</script>
<style scoped>
#info-button {
  font-size: small;
  width: fit-content;
}

#tooltip-button {
  z-index: 999;
  display: none;
  background-color: rgba(255, 255, 255);
  padding: 5px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: fixed;
}

#info-button:hover #tooltip-button {
  display: block;
}
</style>
